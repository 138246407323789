import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import template from './ModificationProfil.Template.vue';
import { UtilisateurMotDePasse } from '@/views/Compte/Profil/models';
import { Societe, Utilisateur, Civilite } from '@/models';
import { MapUtilisateur } from '@/components/FormsUtilisateur/helpers/MapUtilisateur';
import { ApiService } from '@/services/base/ApiService';
import ModifierUtilisateur from '@/components/FormsUtilisateur/ModifierUtilisateur.vue';
import IdentityForm from '@/components/FormsUtilisateur/IdentityForm.vue';
import SocieteForm from '@/components/FormsUtilisateur/SocieteForm.vue';
import ModifierPasswordForm from '@/components/FormsUtilisateur/ModifierPasswordForm.vue';
import EmailModifForm from '@/components/FormsUtilisateur/EmailModifForm.vue';
import ValidationAction from '@/components/ValidationAction.vue';
import { UtilisateurIdentity, UtilisateurEmail } from '@/components/FormsUtilisateur/models/';
import { isEqual, cloneDeep } from 'lodash-es';
import UtilisateurApiHelper from '../../../services/UtilisateurApiHelper';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { Getter, Mutation, Action } from 'vuex-class';
import { AuthStoreMethods } from '../../../store/modules/auth/AuthStore';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';

@Component({
    ...template,
    name: 'ModificationProfil',
    components: {
        ModifierUtilisateur,
        IdentityForm,
        SocieteForm,
        EmailModifForm,
        ModifierPasswordForm,
        ValidationAction,
        ValidationProvider,
        ValidationObserver,
    },
})

export default class ModificationProfil extends Vue {
    public $refs!: {
        formSociete: HTMLFormElement,
    };
    @Prop({ default: '' }) public readonly typeCompte!: string;

    private modifmdp: UtilisateurMotDePasse = new UtilisateurMotDePasse(-1, '', '', '');

    /**
     * Désactive les différents boutons lors de l'appel au serveur.
     */
    public loading: boolean = false;

    @Getter(getterKeyReferentiel(TypeValeurReferentielle.Civilite))
    public civilites: Civilite[];
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;

    @Getter(UtilisateurStoreMethods.UTILISATEUR)
    private importedUser: Utilisateur;

    @Mutation(UtilisateurStoreMethods.SET_UTILISATEUR)
    private setUser: (user: Utilisateur) => void;

    @Getter(AuthStoreMethods.USER_PROFILE)
    private getUserProfile: Promise<any>;

    private originalUserIdentity: UtilisateurIdentity = new UtilisateurIdentity();
    private userId!: number;
    private userIdentity: UtilisateurIdentity = new UtilisateurIdentity();

    private userEmail: UtilisateurEmail = new UtilisateurEmail();
    private originalUserSociete: Societe[] = new Array<Societe>();
    private userSociete: Societe[] = new Array<Societe>();

    public mounted(): void {
        this.getUserProfile.then((profile: any) => {
            if (!!profile) {
                this.userId = parseInt(profile.sub, 10);
                this.getUserById(this.userId).then((utilisateurResponse: Utilisateur) => {
                    if (utilisateurResponse) {
                        this.setUser(utilisateurResponse);
                        this.originalUserIdentity = MapUtilisateur.toIdentity(this.importedUser);
                        this.originalUserIdentity.userId = parseInt(this.$route.params.id, 10);
                        this.originalUserSociete = MapUtilisateur.toSociete(this.importedUser);
                    }
                });
            }
        });
        this.getValeursReferentielles(TypeValeurReferentielle.Civilite);
    }

    // Submit l'onglet Identité.
    public updateIdentity(params: { observerIdentity: InstanceType<typeof ValidationObserver> }): void {
        const { observerIdentity } = params;
        observerIdentity.validate().then(() => {
            this.loading = true;
            this.userIdentity = MapUtilisateur.toIdentity(this.importedUser);
            UtilisateurApiHelper.dispatchModifierUtilisateurIdentite({ utilisateurIdentite: { ...this.userIdentity } })
                .then((result: boolean) => {
                    this.redirection(result);
                })
                .finally(() => {
                    this.loading = false;
                });
        });
    }

    /**
     * Update le mail.
     * @param params
     */
    public updateEmail(params: { observerEmail: InstanceType<typeof ValidationObserver> }): void {
        const { observerEmail } = params;
        observerEmail.validate().then(() => {
            this.loading = true;
            UtilisateurApiHelper.dispatchModifierUtilisateurEmail({ utilisateurEmail: { ...this.userEmail } })
                .then((res: boolean) => {
                    this.redirection(res);
                })
                .finally(() => {
                    this.loading = false;
                });
        });
    }

    public updateSociete(params: { refFormSociete: HTMLFormElement }) {
        const { refFormSociete } = params;
        if (refFormSociete.validate()) {
            this.loading = true;
            this.userSociete = MapUtilisateur.toSociete(this.importedUser);
            UtilisateurApiHelper.dispatchModifierUtilisateurEntreprise({ utilisateurSocietes: this.userSociete })
                .then((result: boolean) => {
                    this.redirection(result);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    /**
     * Submit l'onglet modification mot de passe
     * @param params
     */
    public submitModifMdp(params: { observerPassword: InstanceType<typeof ValidationObserver> }): void {
        const { observerPassword } = params;
        observerPassword.validate().then(() => {
            this.loading = true;
            UtilisateurApiHelper.dispatchModifierUtilisateurMotDepasse({ mdpUtilisateur: { ...this.modifmdp } })
                .then((result: boolean) => this.redirection(result))
                .finally(() => {
                    this.loading = false;
                    observerPassword.reset();
                });
        });
    }

    public getUserById(id: number): Promise<Utilisateur> {
        const utilisateurEdit = new ApiService<Utilisateur>('utilisateur/obtenir');
        return new Promise<Utilisateur>((resolve, reject) => {
            return utilisateurEdit.get(id).then((response) => {
                resolve(response.data.data);
            }).catch((error: { response: any; }) => {
                reject(error.response);
            });
        });
    }
    public refreshEmail(value: UtilisateurEmail): void {
        this.userEmail = value;
    }
    public refreshPassword(value: UtilisateurMotDePasse): void {
        this.modifmdp = new UtilisateurMotDePasse(this.userId, value.ancienMotDePasse, value.nouveauMotDePasse, value.confirmMotDePasse);
    }
    public isSocieteDisabled(): boolean {
        return this.importedUser.societes.length === 0;
    }
    public isIdentitySubmitDisabled(): boolean {
        return isEqual(MapUtilisateur.toIdentity(this.importedUser), this.originalUserIdentity);
    }
    public isSocieteSubmitDisabled(): boolean {
        return isEqual(MapUtilisateur.toSociete(this.importedUser), this.originalUserSociete);
    }
    /**
     * Fait une redirection sur la page 'Modification de profil'.
     * @param reponse
     */
    private redirection(reponse: boolean): void {
        if (reponse) {
            (this.$router as any).push({ name: 'modification-profil' });
        }
    }
    /**
     * Permet de faire un clonage profond.
     * @param reponse
     */
    private cloneDeep(value: object): object {
        return cloneDeep(value);
    }
}
